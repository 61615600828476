import React, {useEffect} from 'react';
import { Button } from '@material-ui/core';
import {COLORS} from "../../utils/Colors";
import QR from "../../assets/qrAvis.png";
import Slider from "react-slick";
import chimney from "../../assets/cheminee.jpg"
import roof from "../../assets/toit.png"
import house from "../../assets/house.jpg"
import house1 from "../../assets/house1.jpg"
import house2 from "../../assets/house2.png"
import {useWindowSize} from "../../utils/Windows";
import CarouselSlider from "../../components/slider/Slider";
import BlurCard from "../../components/card/BlurCard";


const images = [
    { large: chimney, small: house },  // Remplacez par vos URLs d'images
    { large: house1, small: house1 },
    { large: roof, small: house2 }
];



const Opinions: React.FC = () => {
    const windowSize = useWindowSize();  // add this line

    const handleLeaveReviewClick = () => {
        window.open(
            'https://www.google.com/localservices/prolist?spp=Cg0vZy8xMXNmMzJjZGpr&src=2&slp=UhUIARIREg8iDS9nLzExc2YzMmNkams#ts=3',
            '_blank'
        );
    };

    return (
        <div style={{ paddingTop: '10vh', height: '100vh', overflow: 'hidden' }}>
            <CarouselSlider></CarouselSlider>
            <BlurCard>
                { windowSize.width && windowSize.width <= 768 ? (// If screen width is less than or equal to 768px
                    <div>
                        <p style={{ fontFamily: "Montserrat", fontWeight: "bold", fontSize: 15, color: COLORS.light, padding: 20 }}>
                            Je serais ravi d'entendre ce que vous pensez !<br />Partagez votre expérience et aidez-moi à mieux vous servir.
                        </p>
                        <div style={{ display: 'block', textAlign: 'center', marginTop: '20px' }}>
                            <Button variant="contained" style={{
                                backgroundColor: COLORS.gold, color: COLORS.dark,
                                fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 15
                            }} onClick={handleLeaveReviewClick}>
                                Laisser un avis
                            </Button>
                            <p style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20, color: COLORS.light, padding: 20 }}>
                                Ou
                            </p>
                            <img src={QR} alt="QR Code" style={{ width: '50%', height: '50%', marginBottom: '10px' }} />
                        </div>
                    </div>
                ) : (
                    <div>
                        <p style={{ fontFamily: "Montserrat", fontWeight: "bold", fontSize: 25, color: COLORS.light, padding: 20 }}>
                            Je serais ravi d'entendre ce que vous pensez !<br />Partagez votre expérience et aidez-moi à mieux vous servir.
                        </p>
                        <div style={{ display: 'block', textAlign: 'center', marginTop: '20px' }}>
                            <Button variant="contained" style={{
                                backgroundColor: COLORS.gold, color: COLORS.dark,
                                fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20
                            }} onClick={handleLeaveReviewClick}>
                                Laisser un avis
                            </Button>
                            <p style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 30, color: COLORS.light, padding: 20 }}>
                                Ou
                            </p>
                            <img src={QR} alt="QR Code" style={{  width: '40%', height: '40%', marginBottom: '10px' }} />
                        </div>
                    </div>
                )}
            </BlurCard>
        </div>
    );
};

export default Opinions;
