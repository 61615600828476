import React, {useEffect} from 'react';
import {useWindowSize} from "../../utils/Windows";

interface BlurCardProps {
    children?: React.ReactNode;
}
const BlurCard: React.FC<BlurCardProps> = ({children}) => {
    const windowSize = useWindowSize();

    return (
        <div style={{
            position: 'absolute',
            top: `calc(50% + 5vh)`,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            backdropFilter: 'blur(15px)',
            WebkitBackdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(47,49,59, 0.4)',
            padding: '20px',
            borderRadius: '10px',
            width: windowSize.width && windowSize.width <= 768 ? '80%' : '50%'
        }}>
            {children}
        </div>
    );
};

export default BlurCard;
