import React from 'react';
import "../../css/Home.css";
import { COLORS } from "../../utils/Colors";
import QR from "../../assets/qrSms.jpeg";
import {Button, ButtonBase, Divider, IconButton} from "@material-ui/core";
import CarouselSlider from "../../components/slider/Slider";
import { useWindowSize } from "../../utils/Windows";
import BlurCard from '../../components/card/BlurCard';
import { useMusic } from '../../context/MusicContext';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const Home: React.FC = () => {
    const windowSize = useWindowSize();
    const { isPlaying, toggleMusic } = useMusic();

    const handleSmsClick = () => {
        window.location.href = 'sms:+33651288307?&body=Indiquer la raison de votre contact';
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:serviceramonage@orange.fr?subject=Ramonage Web&body=Indiquer la raison de votre contact:';
    };

    return (
        <div style={{ paddingTop: '10vh', height: '100vh', overflow: 'hidden' }}>
            <CarouselSlider />
            <BlurCard>
                <p style={{
                    fontFamily: "Nunito", fontWeight: "bold", color: COLORS.light,
                    fontSize: windowSize.width && windowSize.width <= 768 ? '1rem' : '2.5rem'
                }}>
                    Service de ramonage
                </p>

                <p style={{
                    fontFamily: "Nunito",
                    fontWeight: "bold",
                    color: COLORS.light,
                    fontSize: windowSize.width && windowSize.width <= 768 ? '1rem' : '1.5rem',
                    paddingBottom: '10px'
                }}>
                    04870 St. Michel Observatoire
                </p>

                <ButtonBase onClick={toggleMusic} style={{alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <IconButton color="primary" style={{ backgroundColor: COLORS.gold, color: COLORS.dark, marginRight: '10px' }}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <span style={{ fontFamily: "Nunito", fontWeight: "bold", fontSize: 20, color: COLORS.gold }}>
                        La retraite est arrivée, merci !
                    </span>
                </ButtonBase>


                {windowSize.width && windowSize.width <= 768 ? (
                    <div>
                        <Divider style={{ backgroundColor: COLORS.light, margin: '20px auto', width: '100%', height: '3px', borderRadius: '10px' }} />
                        <Button variant="contained" style={{
                            backgroundColor: COLORS.gold, color: COLORS.dark,
                            fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 15, marginTop: '20px'
                        }} onClick={handleSmsClick}>
                            Par SMS
                        </Button>
                        <p style={{ fontFamily: "Nunito", fontWeight: "bold", color: COLORS.light, margin: '20px' }}>
                            Ou
                        </p>
                        <Button variant="contained" style={{
                            backgroundColor: COLORS.gold, color: COLORS.dark,
                            fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 15
                        }} onClick={handleEmailClick}>
                            Par Email
                        </Button>
                        <p style={{ fontFamily: "Nunito", fontWeight: "bold", color: COLORS.light, margin: '20px' }}>
                            Ou
                        </p>
                        <a href="tel:0492766735" style={{ textDecoration: 'underline', color: COLORS.gold }}>
                            <p style={{ fontFamily: "Nunito", fontWeight: "bold" }}>
                                04 92 76 67 35
                            </p>
                        </a>
                        <p style={{ fontFamily: "Nunito", fontWeight: "normal", color: COLORS.light, fontSize: 15 }}>
                            En cas d'absence, un répondeur prend en charge votre demande
                        </p>
                    </div>
                ) : (

                    <div>
                        <Divider style={{ backgroundColor: COLORS.light, margin: '20px auto', width: '80%', height: '3px', borderRadius: '10px' }} />

                        <a href="mailto:serviceramonage@orange.fr?subject=Ramonage Web&body=Indiquer la raison de votre contact:"
                           style={{ textDecoration: 'underline', color: COLORS.gold }}>
                            <p style={{ fontFamily: "Nunito", fontWeight: "bold", fontSize: 20, marginTop: '20px' }}>
                                Par mail à : serviceramonage@orange.fr
                            </p>
                        </a>

                        <p style={{
                            fontFamily: "Nunito",
                            fontWeight: "bold",
                            fontSize: 20,
                            color: COLORS.light,
                            margin: '20px'
                        }}>
                            Ou
                        </p>

                        <a href="tel:0492766735"
                           style={{ textDecoration: 'underline', fontSize: 20, color: COLORS.gold }}>
                            <p style={{ fontFamily: "Nunito", fontWeight: "bold" }}>
                                Par téléphone au : 04 92 76 67 35
                            </p>
                        </a>
                        <p style={{ fontFamily: "Nunito", fontWeight: "normal", color: COLORS.light, fontSize: 20 }}>
                            En cas d'absence, un répondeur prend en charge votre demande
                        </p>

                        <p style={{
                            fontFamily: "Nunito",
                            fontWeight: "bold",
                            color: COLORS.light,
                            fontSize: 20,
                            margin: '20px'
                        }}>
                            Ou
                        </p>

                        <p style={{
                            fontFamily: "Nunito",
                            fontWeight: "bold",
                            color: COLORS.gold,
                            fontSize: 20,
                            margin: '20px'
                        }}>
                            Par SMS en scannant ce QR CODE
                        </p>
                        <img src={QR} alt="QR Code"
                             style={{ marginBottom: '10px', width: '30%', fontSize: 20, height: '30%' }}
                        />

                    </div>
                )}
            </BlurCard>
        </div>
    );
};

export default Home;
