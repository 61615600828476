import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    WithStyles,
    createStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { COLORS } from "../../utils/Colors";

interface Props extends WithStyles<typeof styles> {}

const styles = createStyles ({
    tableContainer: {
        padding: '20px',
        boxShadow: 'none',
    },
    tableHeaderCell: {
        backgroundColor: COLORS.light,
        color: COLORS.dark,
        borderBottom: `0.75px solid ${COLORS.gold}`,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    },
    tableCell: {
        padding: '10px',
        borderBottom: `0.75px solid ${COLORS.gold}`,
        fontFamily: 'Montserrat',
        fontSize: '16px',
    },
});

const Prices: React.FC<Props> = ({ classes }) => {
    const TableData = [
        { travail: 'INSERT', information: '', prix: '85.00' },
        { travail: 'INSERT & JOINTS', information: '', prix: '94.00' },
        { travail: 'CHEMINÉE À BOIS FOYER OUVERT', information: '', prix: '77.00' },
        { travail: 'CONDUIT SEUL', information: '', prix: '67.00' },
        { travail: 'POÊLE SEUL', information: '', prix: '75.00' },
        { travail: 'POÊLE ET CONDUIT DE CHEMINÉE', information: '', prix: '84.50' },
        { travail: 'CUISINIÈRE À BOIS & CONDUIT', information: '', prix: '93.50' },
        { travail: 'CUISINIÈRE À FIOUL & CONDUIT', information: '', prix: '92.00' },
        { travail: 'CHAUDIÈRE MAX. 35 KW', information: 'conduit inclus', prix: '85.00' },
        { travail: 'CHAUDIÈRE MAX. 75 KW', information: 'conduit inclus', prix: '94.00' },
        { travail: 'CHAUDIÈRE MAX. 100 KW', information: 'conduit inclus', prix: '115.00' },
        { travail: 'SUPPLÉMENT CHAUDIÈRE À BOIS', information: '', prix: '30.00' },
        { travail: 'FOUR À PAIN PROFESSIONNEL', information: '', prix: '150.00' },
        { travail: 'FOUR À PIZZA PROFESSIONNEL', information: '', prix: '125.00' },
        { travail: 'NETTOYAGE CARBURATEUR POÊLE À FIOUL', information: 'sans tuyaux et réservoir', prix: '70.00' },
        { travail: 'DÉPLACEMENT', information: '(hors saison)', prix: '30.00' },
        { travail: 'FRAIS ADMINISTRATIFS', information: 'exceptionnel', prix: '8.00' },
        { travail: 'TUYAU(X) DE FUMÉE', information: '', prix: '1.80' },
        { travail: 'VENTILATEUR EXTRACTEUR', information: '', prix: '65.00' },
        { travail: 'RÉCUPÉRATEUR DE CHALEUR PETITE TAILLE', information: '', prix: '21.00' },
        { travail: 'RÉCUPÉRATEUR DE CHALEUR GRANDE TAILLE', information: '', prix: '28.00' },
        { travail: 'NETTOYAGE CARBURATEUR AVEC TUYAUX ET RÉSERVOIRE', information: '', prix: '70.00' },
        { travail: 'FOUR BOULANGERIE INDUSTRIELLE', information: '', prix: '240.00' },
        { travail: 'POÊLE SUÉDOIS', information: 'avec démontage et conduit inclus', prix: '98.00' },
        { travail: 'SUPPLÉMENT CHEMINÉE GRANDE SECTION', information: '', prix: '18.00' },
        { travail: 'POÊLE À GRANULÉS', information: '', prix: '150.00' },    ];

    return (
        <div style={{ paddingTop: '10vh' }}>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>Prestation</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Information</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {TableData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                    {item.travail}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{item.information}</TableCell>
                                <TableCell className={classes.tableCell}>{item.prix}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default withStyles(styles)(Prices);
