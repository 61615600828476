import React, { useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/header/NavBar";
import Home from "./views/home/Home";
import Opinions from "./views/opinions/Opinions";
import Prices from "./views/prices/Prices";
import Legislation from "./views/legislation/Legislation";
import Standard from "./views/standard/Standard";
import { MusicProvider } from './context/MusicContext';

function App() {
    return (
        <div className="App">
            <MusicProvider>
                <Router>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/prices" element={<Prices />} />
                        <Route path="/opinions" element={<Opinions />} />
                        <Route path="/legislation" element={<Legislation />} />
                        <Route path="/standard" element={<Standard />} />
                    </Routes>
                </Router>
            </MusicProvider>

        </div>
    );
}
export default App;
