import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../../css/Legislation.css"
import pdfFile from '../../assets/legislation.pdf';
import {COLORS} from "../../utils/Colors";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Legislation: React.FC = () => {
    const [numPages, setNumPages] = useState(0);

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const renderPages = (numPages: number) => {
        let pages = [];

        for (let i = 1; i <= numPages; i++) {
            pages.push(
                <div>
                <Page
                    key={`page_${i}`}
                    pageNumber={i}
                    width={window.innerWidth}
                    renderTextLayer={false}
                />
                <hr className="custom-hr" />
                </div>
        );
        }
        return pages;
    };

    return (
        <div style={{paddingTop: '11vh' }}>
            <p style={{ fontFamily: "Montserrat", fontWeight: "bold", color: COLORS.dark, padding: 20 }}>
                Toute personne effectuant un ramonage par nos soins a pris note de ces textes et les accepte.
            </p>
            <hr className="custom-hr" />

            <div>
                <Document
                    file={pdfFile}
                    onLoadSuccess={handleDocumentLoadSuccess}
                >
                    {renderPages(numPages)}
                </Document>
            </div>
        </div>
    );
};

export default Legislation;
