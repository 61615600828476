import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car from "../../assets/fourgon.png";
import ramoneur from "../../assets/ramoneur_tableau.jpg";
import chimney from "../../assets/cheminee.jpg";
import house from "../../assets/house.jpg";
import house1 from "../../assets/house1.jpg";
import roof from "../../assets/toit.png";
import house2 from "../../assets/house2.png";
import Slider from "react-slick";
import "../../css/Home.css"


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dotsClass: "slick-dots custom-indicator",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const images = [
    { large: car, small: ramoneur},
    { large: chimney, small: house },
    { large: house1, small: house1 },
    { large: roof, small: house2 }
];

const CarouselSlider = () => (
    <Slider {...settings}>
        {images.map((image, index) => (
            <div key={index}>
                <div
                    className="large-image"
                    style={{
                        backgroundImage: `url(${image.large})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative',
                        height: '100vh',
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(47,49,59, 0.4)',
                    }} />
                </div>
                <div
                    className="small-image"
                    style={{
                        backgroundImage: `url(${image.small})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative',
                        height: '100vh',
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(47,49,59, 0.4)',
                    }} />
                </div>
            </div>
        ))}
    </Slider>
);

export default CarouselSlider;
