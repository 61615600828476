import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import musicFile from '../assets/music.mp3';

interface MusicContextType {
    isPlaying: boolean;
    toggleMusic: () => void;
}

const MusicContext = createContext<MusicContextType | undefined>(undefined);

export const useMusic = () => {
    const context = useContext(MusicContext);
    if (!context) {
        throw new Error('useMusic must be used within a MusicProvider');
    }
    return context;
};

export const MusicProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(() => {
        const savedState = localStorage.getItem('isMusicPlaying');
        return savedState ? JSON.parse(savedState) : false;
    });
    const audioRef = useRef<HTMLAudioElement>(new Audio(musicFile));

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play().catch(error => {
                console.error("Playback prevented:", error);
            });
        } else {
            audioRef.current.pause();
        }

        return () => {
            audioRef.current.pause();
        };
    }, [isPlaying]);

    useEffect(() => {
        localStorage.setItem('isMusicPlaying', JSON.stringify(isPlaying));
    }, [isPlaying]);

    const toggleMusic = () => {
        setIsPlaying(prevState => !prevState);
    };

    return (
        <MusicContext.Provider value={{ isPlaying, toggleMusic }}>
            {children}
        </MusicContext.Provider>
    );
};
