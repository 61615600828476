import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import '../../css/NavBar.css';
import logo from '../../assets/logo-no-bg.png';
import menu from '../../assets/menu.png';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    const navLink = (url: string, label: string) => (
        <li className="navbar-item">
            <Link to={url} className="navbar-link" onClick={closeMenu}>{label}</Link>
        </li>
    );

    return (
        <nav className="navbar">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <button className={`burger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <img src={menu} alt="Menu" />
            </button>
            <ul className={`navbar-list ${isOpen ? 'open' : ''}`}>
                {navLink("/", "Accueil")}
                {navLink("/prices", "Tarifs")}
                {navLink("/opinions", "Avis")}
                {navLink("/legislation", "Législation")}
                {navLink("/standard", "Normes")}
            </ul>
        </nav>
    );
};

export default Navbar;
